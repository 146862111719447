'use strict';

import { isEmpty, trim } from 'lodash';

export default {
    computed: {
        seoEntry() {
            return this.entry;
        },
        seoTitle() {
            if (this.seoEntry) {
                if (this.seoEntry.title) {
                    return this.$config.site.name + ' | ' + this.seoEntry.title;
                }
            }

            return this.$config.site.name;
        },
        seoDescription() {
            if (this.seoEntry) {
                if (this.seoEntry.seo && this.seoEntry.seo.description) {
                    return this.seoEntry.seo.description;
                } else if (this.seoEntry.description) {
                    return this.seoEntry.description;
                }
            }

            return this.$config.site.description;
        },
        seoRobots() {
            if (this?.seoEntry?.seo?.advanced?.robots) {
                return this.seoEntry.seo.advanced.robots.map((content) => {
                    return { name: 'robots', content };
                });
            }

            return [];
        },
        seoImage() {
            if (this.seoEntry) {
                if (this.seoEntry.featuredImage && this.seoEntry.featuredImage[0] && this.seoEntry.featuredImage[0].w768) {
                    return this.seoEntry.featuredImage[0];
                } else if (this.seoEntry.image && this.seoEntry.image[0] && this.seoEntry.image[0].w768) {
                    return this.seoEntry.image[0];
                } else if (this.seoEntry.imageMobile && this.seoEntry.imageMobile[0] && this.seoEntry.imageMobile[0].w768) {
                    return this.seoEntry.imageMobile[0];
                } else if (this.seoEntry.imageCard && this.seoEntry.imageCard[0] && this.seoEntry.imageCard[0].w768) {
                    return this.seoEntry.imageCard[0];
                }
            }
            return '';
        },
        seoSocial() {
            if (this.seoEntry && this.seoEntry.seo) {
                return this.seoEntry.seo.social || {};
            }

            return {};
        },
        seoFacebook() {
            if (this.seoSocial) {
                return this.seoSocial.facebook || {};
            }

            return {};
        },
        seoTwitter() {
            if (this.seoSocial) {
                return this.seoSocial.twitter || {};
            }

            return {};
        },
        seoFacebookImage() {
            if (this.seoFacebook) {
                return this.seoFacebook.image || {};
            }

            return {};
        },
        seoTwitterImage() {
            if (this.seoTwitter) {
                return this.seoTwitter.image || {};
            }

            return {};
        },
        seoMeta() {
            return [
                { hid: 'description', name: 'description', content: this.seoDescription || '' },
                // FACEBOOK
                { hid: 'og:type', property: 'og:type', content: 'website' },
                { hid: 'og:title', property: 'og:title', content: this.seoFacebook.title || this.seoTitle },
                { hid: 'og:description', property: 'og:description', content: this.seoFacebook.description || this.seoDescription || '' },
                { hid: 'og:image', property: 'og:image', content: this.seoFacebookImage.w768 || this.seoImage.w768 || '' },
                // TWITTER
                { hid: 'twitter:title', property: 'twitter:title', content: this.seoTwitter.title || this.seoTitle },
                { hid: 'twitter:description', property: 'twitter:description', content: this.seoTwitter.description || this.seoFacebook.description || this.seoDescription || '' },
                { hid: 'twitter:image', property: 'twitter:image', content: this.seoTwitterImage.w768 || this.seoFacebookImage.w768 || this.seoImage.w768 || '' }
            ];
        },
        seoCanonical() {
            return this?.seoEntry?.seo?.advanced?.canonical;
        },
        seoAttributeLinks() {
            const links = [];

            // Create hreflang links based on CMS data
            if (!isEmpty(this.entry?.localized)) {
                this.entry.localized.forEach((locale) => {
                    links.push({
                        hid: 'i18n-alt-' + locale.language,
                        rel: 'alternate',
                        hreflang: locale.language,
                        href: locale.url,
                    });
                });
            }

            // Create x-default hreflang link
            links.push({
                hid: 'i18n-alt-xd',
                rel: 'alternate',
                hreflang: 'x-default',
                href: 'https://www.gladwellacademy.com/'
            });

            // Current page provided by Nuxt
            let fullURL = trim(this.$config.baseURL,'/') + '/' + this.$route.fullPath.replace(/\/+$/, '');;

            // Create self-referencing hreflang link
            links.push({
                hid: 'i18n-alt-' + this.$i18n.locale,
                rel: 'alternate',
                hreflang: this.$i18n.locale,
                href: this.entry?.url || fullURL
            });

            // Create self-referencing canonical link
            links.push({
                hid: 'i18n-can',
                rel: 'canonical',
                href: this.seoCanonical || fullURL
            });

            return links;
        },
        seoHead() {
            const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: false });
            console.log(i18nHead);
            return {
                htmlAttrs: {
                    ...i18nHead.htmlAttrs
                },
                title: this.seoTitle,
                meta: [
                    ...this.seoMeta,
                    ...this.seoRobots
                ],
                link: this.seoAttributeLinks
            };
        }
    },
    head() {
        return this.seoHead;
    }

};
